<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '720px' }"
    header="Cliente Detalle"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6">
          <span
            ><strong>CLIENTE/PACIENTE: </strong>
            <span class="p-invalid">*</span></span
          >
          <InputText
            v-model.trim="data_cliente.nombre"
            required="true"
            autofocus
          />
          <small class="p-invalid" v-if="errors.nombre">{{
            errors.nombre[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span
            ><strong>SEXO: <span class="p-invalid">*</span> </strong></span
          >
          <Dropdown
            v-model="data_cliente.sexo"
            :options="sexo"
            optionLabel="label"
            placeholder="Seleccione un sexo..."
          >
          </Dropdown>
          <small class="p-invalid" v-if="errors.sexo">{{
            errors.sexo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span
            ><strong>EDAD: <span class="p-invalid">*</span></strong
            ><strong class="p-invalid">{{
              edadMesesCliente ?? ""
            }}</strong></span
          >
          <InputText
            v-model="data_cliente.fecha_nacimiento"
            type="date"
            :max="fecha_actual"
          />
          <small class="p-invalid" v-if="errors.fecha_nacimiento">{{
            errors.fecha_nacimiento[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>CI/NIT: </strong></span>
          <InputText id="ci_nit" v-model="data_cliente.ci_nit" />
          <small class="p-invalid" v-if="errors.ci_nit">{{
            errors.ci_nit[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>ESTADO CIVIL: </strong></span>
          <Dropdown
            v-model="data_cliente.estado_civil"
            :options="estado_civil"
            optionLabel="label"
            placeholder="Seleccione un estado civil..."
          >
          </Dropdown>
          <small class="p-invalid" v-if="errors.estado_civil">{{
            errors.estado_civil[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>TEL&Eacute;FONO: </strong></span>
          <InputText
            id="telefono"
            v-model="data_cliente.telefono"
            required="true"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          />
          <small class="p-invalid" v-if="errors.telefono">{{
            errors.telefono[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>CONTACTOS: </strong> </span>
          <Textarea v-model="data_cliente.contactos" rows="1" autoResize />
          <small class="p-invalid" v-if="errors.contactos">{{
            errors.contactos[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-6">
          <span><strong>DOMICILIO: </strong> </span>
          <Textarea
            id="domicilio"
            v-model="data_cliente.domicilio"
            required="true"
            rows="1"
            autoResize
          />
          <small class="p-invalid" v-if="errors.domicilio">{{
            errors.domicilio[0]
          }}</small>
        </div>
        <div
          :class="
            data_cliente.id ? 'field col-12 md:col-4' : 'field col-12 md:col-6'
          "
        >
          <span><strong>CORREO ELECTR&Oacute;NICO: </strong> </span>
          <InputText
            id="correo"
            v-model="data_cliente.correo"
            required="true"
          />
          <small class="p-invalid" v-if="errors.correo">{{
            errors.correo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4" v-show="false">
          <span><strong>OBSERVACI&Oacute;N: </strong> </span>
          <Textarea v-model="data_cliente.observacion" rows="1" autoResize />
          <small class="p-invalid" v-if="errors.observacion">{{
            errors.observacion[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2" v-if="data_cliente.id">
          <span><strong>ESTADO: </strong><span class="p-invalid">*</span></span>
          <Dropdown
            id="estado"
            v-model="data_cliente.estado"
            :disabled="!data_cliente.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <span class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></span
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        :label="data_cliente.id ? 'ACTUALIZAR' : 'GUARDAR'"
        icon="pi pi-save"
        class="p-button-info p-button-lg"
        @click="guardarCliente"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import ClienteService from "@/service/ClienteService";
import TipoDocumentoIdentidadService from "@/service/TipoDocumentoIdentidadService";
export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cliente: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  clienteService: null,
  tipoDocumentoIdentidadService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_cliente: this.cliente,
      tipo_documento_selected: {},
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
        { label: "OBITADO", value: 2 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      tipos_documentos: [],
      fecha_actual: new Date().toISOString().slice(0, 10),
      sexo: [
        { label: "MASCULINO", value: "MASCULINO" },
        { label: "FEMENINO", value: "FEMENINO" },
      ],
      estado_civil: [
        { label: "SOLTERO/A", value: "SOLTERO/A" },
        { label: "CASADO/A", value: "CASADO/A" },
        { label: "DIVORCIADO/A", value: "DIVORCIADO/A" },
        { label: "VIUDO/A", value: "VIUDO/A" },
      ],
    };
  },
  created() {
    this.clienteService = new ClienteService();
    this.tipoDocumentoIdentidadService = new TipoDocumentoIdentidadService();
  },
  computed: {
    edadMesesCliente() {
      if (
        this.data_cliente.fecha_nacimiento &&
        !isNaN(new Date(this.data_cliente.fecha_nacimiento))
      ) {
        let fecha_nacimiento = new Date(this.data_cliente.fecha_nacimiento);
        let fecha_actual = new Date();
        let edad = fecha_actual.getFullYear() - fecha_nacimiento.getFullYear();
        let meses = fecha_actual.getMonth() - fecha_nacimiento.getMonth();
        if (
          meses < 0 ||
          (meses === 0 && fecha_actual.getDate() < fecha_nacimiento.getDate())
        ) {
          edad--;
          meses += 12;
        }
        return edad + " años y " + meses + " meses";
      }
      return "";
    },
  },

  methods: {
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.enviado = false;
    },
    validarFormulario() {
      this.errors = {};
      if (!this.data_cliente.nombre) {
        this.errors.nombre = ["El campo nombre es requerido"];
      }
      if (!this.data_cliente.sexo) {
        this.errors.sexo = ["El campo sexo es requerido"];
      }
      if (!this.data_cliente.fecha_nacimiento) {
        this.errors.fecha_nacimiento = [
          "El campo fecha de nacimiento es requerido",
        ];
      }

      return Object.keys(this.errors).length === 0;
    },
    guardarCliente() {
      if (!this.validarFormulario()) {
        return;
      }
      this.errors = {};
      this.enviado = true;
      if (this.cliente.id) {
        let cliente_enviar = {
          ...this.data_cliente,
          estado: this.data_cliente.estado.value,
          tipo_documento: this.tipo_documento_selected.value,
          sexo: this.data_cliente.sexo.value,
          estado_civil: this.data_cliente.estado_civil
            ? this.data_cliente.estado_civil.value
            : null,
        };
        this.clienteService.updatedCliente(cliente_enviar).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListado");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      } else {
        //CREANDO UN NUEVO PROVEEDOR
        let tmp = this.data_cliente;
        this.data_cliente = {
          ...this.data_cliente,
          estado: this.data_cliente.estado.value,
          tipo_documento: this.tipo_documento_selected.value,
          sexo: this.data_cliente.sexo.value,
          estado_civil: this.data_cliente.estado_civil
            ? this.data_cliente.estado_civil.value
            : null,
        };
        this.clienteService.sendClienteNuevo(this.data_cliente).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
            this.data_cliente = tmp;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 10000,
            });

            this.$emit("actualizarListado", data.cliente);
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    cliente(val) {
      this.data_cliente = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
