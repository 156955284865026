<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >ODONTOLOGIA</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">AGENDA</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <FullCalendar
      :options="calendarOptions"
      :events="events"
      style="font-size: 12px"
    />
    <NuevaCita
      :show="nuevaCitaDialog"
      :cita="cita"
      :consultorios="consultorios"
      :medicoLogueado="medicoLogueado"
      :medicos="medicos"
      @closeModal="ocultarDialog()"
      @actualizarListado="obtenerListadoAgenda()"
    />
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";
import NuevaCita from "@/module/agenda/NuevaCita.vue";
import AgendaService from "@/service/AgendaService";
import MedicoService from "@/service/MedicoService";
import { useAuth } from "@/stores";

export default {
  components: {
    FullCalendar,
    NuevaCita,
  },
  data() {
    return {
      nuevaCitaDialog: false,
      cita: {
        id: null,
        medico_id: null,
        nombre_medico: null,
        consultorio_id: null,
        nombre_consultorio: null,
        cliente_id: null,
        nombre_cliente: null,
        detalle: null,
        fecha_inicio: null,
        fecha_fin: null,
        duracion: null,
        color: null,
        textColor: null,
        estado: null,
      },
      agendas: [],
      loading: false,
      events: [],
      consultorios: [],
      medicos: [],
      medicoLogueado: false,
      medicoSelected: 0,
    };
  },
  agendaService: null,
  medicoService: null,
  auth: null,

  created() {
    this.agendaService = new AgendaService();
    this.medicoService = new MedicoService();
    this.auth = useAuth();
  },
  mounted() {
    this.cargarMedicos();
    this.obtenerListadoAgenda();
  },
  computed: {
    calendarOptions() {
      const agendasFiltradas = this.medicoLogueado
        ? this.agendas.filter(
            (agenda) => agenda.medico_id === this.medicoSelected
          )
        : this.agendas;
      return {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: "timeGridDay", // Puedes cambiar la vista inicial según tu preferencia
        locales: [esLocale],
        buttonText: {
          today: "Hoy",
          week: "Semana",
          day: "Día",
        },

        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        // Limitar los horarios y mostrar am o pm
        slotMinTime: "08:00:00",
        slotMaxTime: "20:00:00",
        slotLabelFormat: {
          hour: "numeric",
          minute: "2-digit",
          meridiem: "short",
        },

        height: "auto",
        allDaySlot: false,
        selectable: true,
        selectMirror: true,
        dateClick: this.agregarCita,
        eventClick: this.agregarCita,

        events: agendasFiltradas.map((agenda) => {
          return {
            id: agenda.id,
            title: `${agenda.nombre_cliente} - ${agenda.nombre_medico} - ${agenda.nombre_consultorio}`,
            start: agenda.fecha_inicio,
            end: agenda.fecha_fin,
            backgroundColor: agenda.color,
            textColor: agenda.text_color,
            extendedProps: {
              detalle: agenda.detalle,
              cliente_id: agenda.cliente_id,
              medico_id: agenda.medico_id,
              consultorio_id: agenda.consultorio_id,
              estado: agenda.estado,
            },
          };
        }),
      };
    },
  },
  methods: {
    obtenerListadoAgenda() {
      this.loading = true;
      this.agendaService.obtenerListadoAgenda().then((data) => {
        this.agendas = data.agendas || [];
        this.consultorios = data.consultorios || [];
        this.loading = false;
      });
    },
    ocultarDialog() {
      this.nuevaCitaDialog = false;
    },
    agregarCita(info) {
      //verifico si es un evento existente
      if (info.event) {
        this.editarCita(info.event);
        return;
      }
      // Verifico que le dio click en lo correcto
      if (!info.dateStr) {
        return;
      }
      //verifo si esta correcto para transformar la fecha
      if (info.dateStr.split("T").length < 2) {
        return;
      }
      const fecha = info.dateStr.split("T")[0].split("-").reverse().join("/");
      const hora = info.dateStr.split("T")[1].substring(0, 5);
      const duracion = 30; // Duración de la cita en minutos
      const color = "#007dd7"; // Color de la cita
      const medico_id = this.medicoSelected;
      this.cita = { fecha, hora, duracion, color, medico_id };

      this.nuevaCitaDialog = true;
    },
    editarCita(event) {
      if (!event) return;
      const extendedProps = event.extendedProps || {};
      this.cita = {
        id: event.id,
        fecha: event.startStr.split("T")[0].split("-").reverse().join("/"),
        hora: event.startStr.split("T")[1].substring(0, 5),
        duracion: (new Date(event.end) - new Date(event.start)) / 60000,
        textColor: extendedProps.textColor,
        color: event.backgroundColor,
        detalle: extendedProps.detalle || "",
        cliente_id: extendedProps.cliente_id || null,
        nombre_cliente: event.title.split(" - ")[0],
        medico_id: extendedProps.medico_id || null,
        nombre_medico: event.title.split(" - ")[1],
        consultorio_id: extendedProps.consultorio_id || null,
        nombre_consultorio: event.title.split(" - ")[2] || "",
        estado: extendedProps.estado || null,
      };
      this.nuevaCitaDialog = true;
    },
    cargarMedicos() {
      this.medicoService.getMedicosAll().then((data) => {
        this.medicos = data.medicos || [];
        if (this.medicos.length > 0) {
          let medico = this.medicos.find(
            (medico) => medico.nombre_completo === this.auth.user.name
          );
          if (medico) {
            this.medicoSelected = medico.id;
            this.medicoLogueado = true;
          } else {
            this.medicoSelected = 0;
            this.medicos.unshift({ id: 0, nombre_completo: "TODOS" });
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
</style>
